import { Injectable } from '@angular/core';
import {AbstractApiEntityService} from '@app/_modules/_shared/_abstracts/abstract-api-entity.service';
import {IGroup} from '@app/_modules/_groups/_models/group.interface';
import {environment} from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import {CacheService} from '@app/_services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ApiGroupService extends AbstractApiEntityService<IGroup> {
  override api = {
    check: environment.url_check_group,
    create: environment.url_create_group,
    get: environment.url_get_group,
    update: environment.url_update_group,
    delete: environment.url_delete_group,
    list: environment.url_get_group_search
  }

  constructor(http: HttpClient,
              cacheService: CacheService) {
    super(http, cacheService);
  }

  checkGroup(group_code: string) {
    return this.checkEntity(group_code);
  }

  createGroup(group: IGroup) {
    return this.createEntity(group);
  }

  getGroup(group_code: string) {
    return this.getEntity(group_code);
  }

  updateGroup(group: IGroup) {
    const group_code = group.code;
    return this.updateEntity(group_code, group);
  }

  deleteGroup(group_code: string) {
    return this.deleteEntity(group_code);
  }

  getGroupList(query: string = '') {
    return this.getEntityList(query);
  }
}
